@import "../../../resources/style/colors";
@import "../../../resources/style/global";

@media (min-width: 901px) {
  .fft-header {
    display: none;
  }
}

@media (max-width: 900px) {
  .fft-header {
    width: 100%;
    order: -1;
    display: flex;
    justify-content: center;
    background-color: $fft-blue;
    margin-bottom: 5vh;
    height: 10vh;

    @each $name, $color in $colors-footer {
      &##{$name} {
        background-color: $color;
      }
    }

    img {
      max-width: 48vw;
      height: 10vh;
      align-self: center;
      content: url("../../../resources/img/logos/logo-FFT.svg");
      position: relative;
      top: 5vh;

      @each $logo, $src in $logos {
        &##{$logo} {
          content: url(#{$src});
          @if $logo == TENUP {
            top: 0vh;
            height: auto;
            margin: auto;
          }
        }
      }
    }
  }
}
