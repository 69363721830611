@import '../../../resources/style/global';

@media (max-width: 900px) {
  .logos {
    display: none;
  }
}
@media (min-width: 901px) {
  .logos {
    height: 100%;
    padding: 0 8vw;
    flex-grow: 1;
    display: flex;
    gap: 50px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .logo-primary {
    max-width: 20vw;
    height: auto;
    max-height: 150px;
    content: var(--bg-logo-src);

    @each $logo, $src in $logos {
      &##{$logo} {
        --bg-logo-src: url(#{$src});
        @if $logo == TENUP {
          height: 9vh;
        }
      }
    }
  }

  .logo-secondary {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo-secondary-item {
    width: auto;
    height: auto;
    max-height: 100%;
    content: var(--bg-logo-src);
    
    @each $logo-white, $src in $logos-white {
      &##{$logo-white} {
        --bg-logo-src: url(#{$src});
      }
    }
  }
}
