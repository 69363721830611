/* PRIMARY */
$fft-blue: #002e5d;
$tenup-blue: #232346;
$rg-orange: #d1580d;
$rpm-green: #004841;

/* SECONDARY */
$rg-green: #00503c;

$primary-dark: #3c3c3c;
$primary-gray: #c4c4c4;
$primary-light: #ffffff;

/* STATUS */
$disable-bg: #a8a8a7;

/* ALERTS */
$success: #007e33;
$success-bg: #d9f7e5;
$error: #e50000;
$error-bg: #fbd9d9;
$warning: #ff5c00;
$warning-bg: rgba(255, 187, 51, 0.15);
$info: #0099cc;
$info-bg: #e0f4fb;
