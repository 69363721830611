@use "../../../resources/style/global";
@import "../../../resources/style/colors";

.card-dropdown {
  height: 100%;
  align-self: flex-end;
  position: relative;

  img {
    max-width: 30px;
    max-height: 20px;
  }

  > fieldset {
    padding-top: 6px;
    padding-bottom: 8px;
    display: flex;
    gap: 1vw;
    justify-content: center;
    align-items: center;

    #dropdown-button {
      padding: 0;
      margin: 0;
      border: none;
    }
  }

  #country-dropdown {
    top: 43px;
  }
}

#reset-email-form {
  #country-phone-div{
    display: flex;
    gap:12px;
  }
  .invalid {
    color: $error;
    border-color: $error;

    input {
      color: $error;
    }

    input::placeholder {
      color: $error;
    }
  }

  .disable {
    color: $primary-gray;
    border-color: $primary-gray;

    fieldset {
      color: $primary-gray;
      border-color: $primary-gray !important;
    }

    input {
      color: $primary-gray;
      border-color: $primary-gray !important;
    }
  }

  #reset-email-phone {
    width: 100%;



    #phone-inputs-wrapper {
      display: flex;
      gap: 12px;


      //#country-input {
      //  padding-top: 0px;
      //}

      #phone {
        flex-grow: 1;

        fieldset {
          margin: 0;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type="number"] {
          -moz-appearance: textfield;
        }

        #phone-input-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 2px;

          span {
            margin: 4px 0;
          }
        }
      }
    }
  }

  /* FORM SEPARATOR */
  #separator {
    width: 100%;
    min-height: 20px;
    display: flex;
    justify-content: center;
    margin: 0;
    font: 14px GothamBold, sans-serif;
    text-transform: uppercase;
  }

  /* FORM QUADRUPLET */
  #quadruplet-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    #civility {
      justify-content: center;

      select {
        cursor: pointer;
        &:disabled {
          color: $primary-gray;
        }
      }
    }

    #civility select {
      margin-bottom: 6px;
      max-height: 40px;
      padding: 0 20px 0 0;
      border: none;
      background: none;
      color: $primary-dark;
      font: 12px GothamBook, sans-serif;
    }

    #names {
      display: flex;
      justify-content: space-evenly;
      gap: 12px;
    }

    #birthdate-input {
      width: 100%;
      height: auto;
      display: inline;
    }

    #birthdate-input.invalid {
      color: $error;
      border-color: $error;

      input {
        color: $error;
      }

      input::placeholder {
        color: $error;
      }
    }
  }
}
