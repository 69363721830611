@use "../../../../resources/style/global";
@import "../../../../resources/style/colors";

@mixin country {
  display: flex;
  gap: 12px;

  #country {
    cursor: pointer;
    align-self: center;
    height: 50px;
    flex-grow: 1;
    display: flex;
    gap: 4px;

    #country-input {
      align-self: center;

      &>#input {
        cursor: pointer;
      }
    }

    #country-flag {
      width: 35px;
      height: 20px;
      align-self: center;
      object-fit: cover;
      border-radius: 5px;
    }

  }
}

#register-quadruplet-form {
  #quadruplet-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    #country-and-postal-code {
      width: 100%;
      @include country;
      & > div.fft-card-form-item:first-child {
        width: 100%;
      }
    }

    #names {
      display: flex;
      justify-content: space-evenly;
      gap: 12px;
    }

    #birthdate {
      width: 100%;
      height: auto;
      display: inline;
    }

    #birthdate.invalid {
      color: $error;
      border-color: $error;

      input {
        color: $error;
      }

      input::placeholder {
        color: $error;
      }
    }

    #birthdate #minority-check {
      width: 100%;
      padding: 20px 0;
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;

      #minority-checkbox {
        /* Override CardComponent */
        width: auto;
        height: auto;
        margin: 0;
        padding: 0 4px;
        /* ********************** */
        flex-grow: 0;
        border: 1px solid $primary-dark;
        border-radius: 6px;
      }

      #minority-checkbox:hover {
        cursor: pointer;
        background-color: $primary-gray;
      }

      #label {
        flex-grow: 1;
      }
    }
  }

  .invalid {
    color: $error;
    border-color: $error;

    input {
      color: $error;
    }

    input::placeholder {
      color: $error;
    }
  }
}