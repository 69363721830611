@import '../resources/style/_colors.scss';

.home {
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  color: $primary-dark;
  font: 0.85rem GothamBook, sans-serif;
  @media (min-width: 901px) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-size: cover;
    background-repeat: no-repeat;

    &#FFT {
      background-image: url('../resources/img/background-FFT.png');
    }

    &#TENUP {
      background-image: url('../resources/img/background-TENUP.png');
    }

    &#RG {
      background-image: url('../resources/img/background-RG.png');
    }

    &#RPM {
      background-image: url('../resources/img/background-RPM.png');
    }
  }
  @media (max-width: 900px) {
    flex-direction: column;
  }
}
