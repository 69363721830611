@use "../../../resources/style/global";
@import "../../../resources/style/colors";


#countrySearch{
  position: relative;

& > {
  #country-dropdown {
    position: absolute;
    width: 100%;
  }

  #countrySearch-input {
    padding-bottom: 0px;
    padding-top: 0px;
  }

  fieldset {
  margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid #3c3c3c;
  border-radius: 5px;
}
legend {
  width: auto;
  padding: 0 10px;
  margin: 0;
  font-size: 0.85rem;
  line-height: 12px;
  border: none;
  white-space: nowrap;
}

input {
  width: 100%;
  height: 100%;
  margin: 4px 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: #3c3c3c;
  font: 0.85rem GothamBook, sans-serif;
}
}
}
