@import "../../../../resources/style/colors";

#country-dropdown {
  max-height: 20vh;
  //max-width: 315px;
  //min-width: 100%;
  width: 100%;
  //top: -20px;
  left: 0;
  padding: 6px 12px 12px;
  z-index: 1;

  display: flex;
  gap: 6px;
  flex-direction: column;

  overflow-y: auto;
  scrollbar-width: none;
  overflow-x: hidden;
  box-sizing: border-box;
  border: 1px solid $primary-dark;
  border-radius: 5px;
  background-color: $primary-light;

  .country-select {
    width: 100%;
    display: flex;
    gap: 4px;
    flex-direction: row;
    align-items: center;
    align-content: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    padding: 2px 0px;

    >span {
      flex-grow: 7;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    >img {
      width: 35px;
      height: 20px;
      align-self: center;
      object-fit: cover;
      border-radius: 5px;
    }

  }

  .country-select:hover {
    color: $primary-gray !important;
  }
}