@import "src/webapp/resources/style/colors";
@import "src/webapp/resources/style/global";


.register-optin {
  padding: 10px 0;

  .fft-card-form-radio {
    padding: 12px 0 12px 12px;
    display: flex;
    gap: 1vw;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .radio {
    display: flex;
    gap: 0.2vw;

    label {
      align-self: center;
    }

    input[type="radio"]:after {
      width: 15px;
      height: 15px;
      border-radius: 15px;
      top: -2px;
      left: -1px;
      position: relative;
      background-color: $primary-light;
      content: "";
      display: inline-block;
      visibility: visible;
      border: 1.5px solid $primary-dark;
    }

    input[type="radio"]:checked:after {
      width: 15px;
      height: 15px;
      border-radius: 15px;
      top: -2px;
      left: -1px;
      position: relative;
      content: "";
      display: inline-block;
      visibility: visible;
    }

    @each $name,
    $color in $colors {
      ##{$name}:checked:after {
        background-color: $color;
        border: 1.5px solid $color
      }
    }
  }
}