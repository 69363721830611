@use "src/webapp/resources/style/global";
@import "src/webapp/resources/style/colors";

#coordinates-div {
  .invalid {
    color: $error;
    border-color: $error;

    input {
      color: $error;
    }

    input::placeholder {
      color: $error;
    }
  }

  .disable {
    color: $primary-gray;
    border-color: $primary-gray;

    fieldset {
      color: $primary-gray;
      border-color: $primary-gray !important;
    }

    input {
      color: $primary-gray;
      border-color: $primary-gray !important;
    }
  }

  #update-information {
    width: 100%;

    #country-phone-div{
      display: flex;
      gap: 12px;
    }
    #phone-inputs-wrapper {
      display: flex;
      gap: 12px;

      fieldset.no-legend {
        height: 44px;

        &.disable {
          border-color: $primary-gray;
        }

      }

      #country {
        cursor: pointer;
        flex-grow: 0;
        width: 100%;
        justify-content: space-between;

        > fieldset {
          width: 100%;
          justify-content: space-between;
        }

        #country-dropdown {
          top: 43px;
        }
      }

      #phone {
        flex-grow: 1;
        padding: 16px 0 16px;

        fieldset {
          margin: 0;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type="number"] {
          -moz-appearance: textfield;
        }

        #phone-input-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 2px;

          span {
            margin: 4px 0;
          }
        }
      }
    }
  }

  /* FORM SEPARATOR */
  #separator {
    width: 100%;
    min-height: 20px;
    display: flex;
    justify-content: center;
    margin: 0;
    font: 14px GothamBold, sans-serif;
    text-transform: uppercase;
  }

  /* FORM QUADRUPLET */
  #quadruplet-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    #civility {
      justify-content: center;
    }

    #civility select {
      margin-bottom: 6px;
      max-height: 40px;
      padding: 0 20px 0 0;
      border: none;
      background: none;
      color: $primary-dark;
      font: 12px GothamBook, sans-serif;
    }

    #names {
      display: flex;
      justify-content: space-evenly;
      gap: 12px;
    }

    #birthdate-input {
      width: 100%;
      height: auto;
      display: inline;
    }

    #birthdate-input.invalid {
      color: $error;
      border-color: $error;

      input {
        color: $error;
      }

      input::placeholder {
        color: $error;
      }
    }
  }
}