@import "../../../resources/style/colors";
@import "../../../resources/style/global";

.fft-alert {
  white-space: pre-line;
  width: 100%;
  min-height: 20px;
  margin: auto;
  padding: 16px 23px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: default;

  &.success {
    background-color: $success-bg;
    color: $success;
  }
  &.error {
    background-color: $error-bg;
    color: $error;
  }
  &.warning {
    background-color: $warning-bg;
    color: $warning;
  }
  &.info {
    background-color: $info-bg;
    color: $info;
  }
  img {
    width: 20px;
    max-height: 20px;
    margin-right: 12px;
  }
  span {
    font: 12px GothamBook;
    #bold {
      font-family: GothamBold, sans-serif;
    }
  }
  a {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }
}
