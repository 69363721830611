@import "../../../resources/style/colors";

div#code-container {
  margin: auto auto 36px;
  width: 100%;

  fieldset {
    width: 100%;
    padding: 0;
    margin-inline-start: 0;
    margin-bottom: 12px;
    max-width: 372px;
    display: flex;
    justify-content: space-between;
    border: none;

    legend {
      padding-inline-start: 15px;
      padding-bottom: 10px;
    }

    span {
      position: relative;
      width: 42px;
      height: 42px;
      border-color: $primary-dark;
      border: 1px solid;
      border-radius: 5px;
    }

    span.valid {
      border-color: $rg-green;
    }

    span.error {
      border-color: $error;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }

    span > input:placeholder-shown {
      border-color: $primary-gray;
    }

    span > input {
      width: 18px;
      left: 11px;
      bottom: 10px;
      position: absolute;
      padding: 0px;
      padding-bottom: 3px;
      text-align: center;
      border: none;
      border-color: #3c3c3c;
      border-bottom: 0.5px solid;
      background: none;
      font: 16px GothamBook, sans-serif;
    }

    span > input.valid {
      border-color: $rg-green;
      color: $rg-green;
    }

    span > input.error {
      border-color: $error;
      color: $error;
    }
  }

  button#resend-code {
    font-family: GothamBold;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
}
