@import '../../../../resources/style/colors';

.fft-card-header {
  color: $primary-dark;
  font-family: GothamBook, sans-serif;
  white-space: pre-line;
  h1 {
    width: 100%;
    height: auto;
    text-align: center;
    text-transform: uppercase;
    font-family: GothamBold, sans-serif;
    @media (min-width: 901px) {
      font-size: 1.75rem;
      margin: 124px 0 0;
      padding: 16px 0 16px;
    }
    @media (max-width: 900px) {
      font-size: 1rem;
      margin: 8px 0 0;
      padding: 16px 0;
    }
  }
  h2 {
    width: 100%;
    height: auto;
    margin: 0;
    margin-block: 0;
    text-align: center;
    font-size: 1rem;
    font-weight: normal;
    @media (min-width: 901px) {
      padding: 8px 0 24px;
    }
    @media (max-width: 900px) {
      padding: 8px 0;
    }

    #bold {
      font-family: GothamBold, sans-serif;
    }
  }
  #icon {
    width: 100%;
    padding: 16px 0 16px;
    display: flex;
    justify-content: center;
  }
}
