@use "../../../../resources/style/global";
@import "../../../../resources/style/colors";

#civility-box {

  #civility {
    margin: 0;
    max-width: 30%;
    display: flex;
    align-items: center;
    cursor: pointer;

    & > #civility-input {
      cursor: pointer;
    }

    #civility-dropdown {
      max-height: 20vh;
      z-index: 1;
      position: absolute;
      right: 0;
      top: 36px;
      padding: 12px;

      display: flex;
      flex-direction: column;

      overflow-y: auto;
      scrollbar-width: none;
      overflow-x: hidden;
      box-sizing: border-box;
      border: 1px solid $primary-dark;
      border-radius: 5px;
      background-color: $primary-light;

      #civility-select:hover {
        color: $primary-gray;
      }
    }
  }
}
