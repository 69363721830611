@import "src/webapp/resources/style/colors";

#confirm-email-error-input {
  color: $error;

  input {
    color: $error;
  }
}

/* FORM EMAIL */
#email-input {
  width: 100%;
  height: auto;
  justify-content: center;
}

#email-input.invalid {
  color: $error;
  border-color: $error;

  input {
    color: $error;
  }
  input::placeholder {
    color: $error;
  }
}