@import "src/webapp/resources/style/colors";
@import "src/webapp/resources/style/global";

.select-login-item {
  width: 100%;
  height: 4.5rem;

  padding-top: 12px;
  border-top: solid 1px $primary-dark;

  &:first-child {
    border-top: none;
  }

  display: flex;
  flex-direction: row;

  input {
    margin-right: 1rem;
    margin-bottom: 1rem;
    @each $name, $color in $colors {
      &##{$name} {
        accent-color: $color;
      }
    }

  }

  &>.select-account-item-input {
    margin: 1rem;
  }

  .first-column {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    align-items: stretch;
    justify-content: space-evenly;

    margin-right: 0.5rem;
    width: 50%;
    margin-bottom: 0.5rem;

    :is(.login, .license){
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: GothamBold, sans-serif;
      @each $name, $color in $colors {
        &##{$name} {
          color: $color;
        }
      }
    }
  }

  .second-column {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    align-items: flex-end;
    justify-content: space-evenly;

    margin-right: 1rem;

    color: $primary-gray;

    .last-login {
      font-size: 10px;
    }
  }

  .color-gray {
    color: $primary-gray;
  }
  .color-dark {
    color: $primary-dark;
  }
}