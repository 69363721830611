@use 'src/webapp/resources/style/global';
@import 'src/webapp/resources/style/colors';

#identity-div {
    background-color: $primary-gray;
    border-radius: 5%;
    padding: 2%;

    :focus-within>legend {
        font-family: GothamBook, sans-serif;
    }

    /* FORM QUADRUPLET */
    #quadruplet-input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        #civility {
            justify-content: center;
        }

        #civility select {
            margin-bottom: 6px;
            max-height: 40px;
            padding: 0 20px 0 0;
            border: none;
            background: none;
            color: $primary-dark;
            font: 12px GothamBook, sans-serif;
        }

        #names {
            display: flex;
            justify-content: space-evenly;
            gap: 12px;
        }

        #birthdate {
            width: 100%;
            height: auto;
            display: inline;
        }

        fieldset>legend {
            background-color: transparent;
        }
    }
}