@import 'src/webapp/resources/style/colors';
@import 'src/webapp/resources/style/global';

@mixin getCircle($color, $border) {
  border: $border;
  color: $color;
  font: 1rem GothamBook, sans-serif;
}

.fft-card-stepper {
  display: flex;
  justify-content: space-evenly;
  gap: 5px;
  max-width: 325px;
  margin: 0 auto;
  padding: 24px 0;

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding: 8px;
    background: $primary-light;
    @include getCircle($primary-gray, 1px solid $primary-gray);

    &.filled {
      @include getCircle($primary-light, none);
      @each $name,
      $color in $colors {
        &##{$name} {
          background-color: $color;
        }
      }
    }
  }

  .line {
    align-self: center;
    text-align: center;
    flex-grow: 1;
    display: block;
    border-top: 1px solid;
    width: 50px;
    border-color: $primary-gray;

    &.filled {
      @each $name,
      $color in $colors {
        &##{$name} {
          border-color: $color;
        }
      }
    }
  }

  
}