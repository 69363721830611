@use '../../../../resources/style/global';
@import '../../../../resources/style/colors';

.fft-card {
  height: 100%;
  background-color: $primary-light;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $primary-light inset;
    -webkit-box-shadow: 0 0 0 30px $primary-light inset;
  }

  button {
    all: unset;
    cursor: pointer;
  }
}

.fft-card-form {
  .fft-card-form-item {
    fieldset {
      margin: 0 auto;
      box-sizing: border-box;
      border: 1px solid $primary-dark;
      border-radius: 5px;

      &:focus-within>legend {
        font-family: GothamBold, sans-serif;
        white-space: nowrap; 
      }

      &#disabled {
        background: $primary-light;
        
      }
    }

    legend {
      width: auto;
      padding: 0 10px;
      margin: 0;
      font-size: 0.85rem;
      line-height: 12px;
      border: none;
      white-space: nowrap;
    }

    input {
      width: 100%;
      height: 100%;
      margin: 4px 0;
      padding: 0;
      border: none;
      background-color: transparent;
      color: $primary-dark;
      font: 0.85rem GothamBook, sans-serif;

      &:focus {
        outline: none;
      }

      &:disabled::placeholder {
        color: $primary-gray;
      }
    }
  }
}

@media (min-width: 901px) {
  .fft-card {
    max-width: 30vw;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    position: relative;
    margin: 0 0 0 78px;
    padding: 0 6vw;
    display: block;
  }

  /* FORM */
  .fft-card-form {
    position: relative;
    width: 100%;
    max-width: 325px;
    height: auto;
    margin: 0 auto;
    padding: 16px 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .fft-card-form-item {
      padding: 16px 0 16px;
    }
  }
}

@media (max-width: 900px) {
  .fft-card {
    padding: 0 4vw;
    flex-grow: 1;
  }

  /* FORM */
  .fft-card-form {
    position: relative;
    max-width: 325px;
    height: auto;
    margin: 0 auto;
    padding: 16px 8vw;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .fft-card-form-item {
      padding: 12px 0 12px;
    }
  }
}