@import "./colors";

/* FORM */
fieldset {
  position: relative;

  &>legend {
    background-color: white;
  }

}
fieldset.no-legend {
  height: 44px;
}

/* CARD LINKS */
.fft-link {
  margin-top: 8px;
  margin-bottom: 16px;
  text-align: left;
}
.fft-link a {
  color: $primary-dark;
  font-size: 12px;
  text-decoration: none;
}

/* ERROR */
.invalid {
  color: $error;
  
  &>fieldset {
    border-color: $error !important;
  }

  input {
    color: $error !important;
  }
}

/* DISABLE */
.fft-disable {
  color: $primary-gray;

  fieldset {
    color: $primary-gray;
    border-color: $primary-gray !important;
  }
}

/* TITLE */

#header {
  width: 100%;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-content: center;


  #title {
    width: 100%;
    height: auto;
    padding: 16px 0 16px;
    text-align: center;
    text-transform: uppercase;
    font-family: GothamBold, sans-serif;
    font-size: 1.75rem;
  }
}

$alerts: (
  message-error: "../../../resources/img/icons/alert/icon-message-error.svg",
  message-info: "../../../resources/img/icons/alert/icon-message-info.svg",
  message-success: "../../../resources/img/icons/alert/icon-message-success.svg",
  message-warning: "../../../resources/img/icons/alert/icon-message-warning.svg",
);

$logos: (
  FFT: "../../../resources/img/logos/logo-FFT.svg",
  TENUP: "../../../resources/img/logos/logo-TENUP.svg",
  RG: "../../../resources/img/logos/logo-RG.svg",
  RPM: "../../../resources/img/logos/logo-RPM.svg",
);

$logos-white: (
  FFT: "../../../resources/img/logos/white/logo-white-FFT.svg",
  TENUP: "../../../resources/img/logos/white/logo-white-TENUP.svg",
  RG: "../../../resources/img/logos/white/logo-white-RG.svg",
  RPM: "../../../resources/img/logos/white/logo-white-RPM.svg",
);

$colors: (
  FFT: $fft-blue,
  TENUP: $tenup-blue,
  RG: $rg-orange,
  RPM: $rpm-green,
);

$colors-footer: (
  FFT: $fft-blue,
  TENUP: $tenup-blue,
  RG: $rg-green,
  RPM: $rpm-green,
);
