@import "src/webapp/resources/style/colors";
@import "src/webapp/resources/style/global";

.fft-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: GothamBook, sans-serif;

  a {
    cursor: pointer;
    color: $primary-dark;
    font-family: GothamBold, sans-serif;
    &:hover {
      text-decoration: underline;
    }
  }

  #header {

    #icon {
      width: 100%;
      padding: 16px 0 16px;
      display: flex;
      justify-content: center;
    }

    #subtitle {
      display: flex;
      gap: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 901px) {
    margin: 24vh 0 0;
  }
  @media (max-width: 900px) {
    margin: 12vh 0 0;
  }
}
