@import '../../../resources/style/colors';
@import '../../../resources/style/global';

.confirm-reset {
  @media (min-width: 901px) {
    margin: 24vh 0 0;
  }
  @media (max-width: 900px) {
    margin: 12vh 0 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: GothamBook, sans-serif;

  #header {

    #icon {
      width: 100%;
      padding: 16px 0 16px;
      display: flex;
      justify-content: center;
    }
  }

  #subtitle {
    width: 100%;
    padding: 16px 0 16px;
    text-align: center;
    font-size: 1rem;
    b {
      font-family: GothamBold, sans-serif;
    }
    p {
      margin-block: 0;
      margin-inline: 0;
      line-height: 2rem;
    }
    a {
      color: $primary-dark;
      cursor: pointer;
      text-decoration: none;
    }
  }

  #not-received {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 16px 0 24px;
    text-align: center;
  }
}
