@import '../../../resources/style/global';


.loginOauthForm {
  .authorizationList {
    & span {
      text-align: center;
      display: block;
      margin-left: 30px;
    }
  }

  #applicationTitle {
    font-family: GothamBold, sans-serif;
    text-transform: uppercase;

  }

  .fft-card-button {
    & span {
      text-transform: uppercase;
    }
  }

  @each $name, $color in $colors {
    li {
      &##{$name}::marker {
        color: $color;
        font-size: 10px;
      }

      margin-bottom: 15px;

      &:first-child {
        margin-top: 30px;
      }

      &:last-child {
        margin-bottom: 30px;
      }
    }
  }
}