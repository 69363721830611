@import "../../../../../resources/style/colors";

.fft-card {
  .fft-card-flat-button {
    text-decoration: none;

    div {
      width: fit-content;
      height: auto;
      display: flex;
      justify-content: center;
      align-content: center;
      margin: auto;
      padding: 16px 1vw 0;
    }

    a {
      width: 100%;
      display: block;
      color: $primary-dark;
      background: none;
      border: 0;
      border-radius: 5px;
      font: 1rem GothamBold, sans-serif;
      text-decoration: none;
    }
  }
}
