#password-rules {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0 0 8px 0;
  font: 14px GothamBook, sans-serif;

  &.rule {
    width: 100%;
    padding: 4px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  & img {
    width: 16px;
    height: 10px;

    &#checked {
      content: url('../../../../resources/img/icons/alert/icon-check.svg');
    }

    &#cross {
      content: url('../../../../resources/img/icons/alert/icon-cross.svg');
    }
  }
}
