@import "src/webapp/resources/style/colors";
@import "src/webapp/resources/style/global";


#register-optins {
  font-size: 0.7rem;

  #see-more {
    cursor: pointer;
  }
}