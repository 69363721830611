@import "../../../resources/style/colors";
@import "../../../resources/style/global";

.popup-box {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  background: #00000050;
}

.box {
  width: 60%;
  @media (max-width: 900px) {
    width: 80%;
  }
  margin: 4% auto ;

  .header {
    width: 100%;
    height: 70px;
    position: relative;

    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;

    border-radius: 4px 4px 0 0;
    @each $name, $color in $colors {
      &##{$name} {
        background: $color;
      }
    }
    font-family: GothamBold, sans-serif;
    color: $primary-light;

    img {
      filter: brightness(0) invert(1);
    }

    #help-close {
      cursor: pointer;
      position: absolute;
      top: 3px;
      right: 10px;
      font-size: 30px;
    }
  }

  .container {
    height: 75vh;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    position: relative;
    // top: 70px;
    padding-top: 24px;

    background: $primary-light;
    border-radius: 0 0 4px 4px;

    .section {
      padding: 15px 3vw;

      .section-header {
        font: 14px GothamBold, sans-serif;
        margin-bottom: 8px;
      }

      .content {
        line-height: 20px;
      }

      .section-logos {
        @media (max-width: 900px) {
          flex-flow: column;
        }

        display: flex;
        justify-content: space-between;
        margin-top: 24px;

        .section-logo {
          @media (min-width: 901px) {
            flex-flow: column;
          }

          @media (max-width: 900px) {
            margin: 16px 0px;
          }

          display: flex;
          align-items: center;

          img {
            width: 7vh;
            max-width: 95px;
            height: 7vh;
            max-height: 65px;
          }

          .links {
            display: flex;

            @media (min-width: 901px) {
              flex-flow: column;
              align-items: center;
              text-align: center;
            }

            @media (max-width: 900px) {
              margin-left: 20px;
              flex-flow: column;
            }

            a {
              padding: 6px;
              font-size: 10px;
              color: $primary-dark;
            }
          }
        }
      }

      .link-intext {
        font-family: GothamBold, sans-serif;
        color: $primary-dark;
      }
    }
  }
}