@import "../../../resources/style/colors";
@import "../../../resources/style/global";

@media (min-width: 901px) {
  .fft-footer {
    display: none;
  }
}
@media (max-width: 900px) {
  .fft-footer {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    background-color: $fft-blue;

    @each $name, $color in $colors-footer {
      &##{$name} {
        background-color: $color;
      }
    }

    .fft-footer-logo {
      max-width: 20vw;
      max-height: 50px;
      align-self: center;
      content: var(--logo-src);

      @each $logo-white, $src in $logos-white {
        &##{$logo-white} {
          --logo-src: url(#{$src});
        }
      }
    }
  }
}
