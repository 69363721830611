
@import './resources/style/colors';

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

input:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #AAAAAA;
  height: 30px;
}
