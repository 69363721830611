@import "../../../../resources/style/colors";

.fft-card-footer {
  width: 10vw;
  margin: auto;
  min-height: 75px;

  display: flex;
  gap: 48px;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    width: 48vw;
    align-content: space-evenly;
  }

  #fft-card-footer-help {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 55px;
    cursor: pointer;
  }
}
