@import "src/webapp/resources/style/colors";

#select-login {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 0;

  font-size: 14px;
  color: $primary-dark;

  .select-login-wrapper {
    overflow-y: scroll;
    max-height: 27em;
  }

  #select-login-submit {
    padding: 16px 0;
  }
}