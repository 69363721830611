@use '../../../resources/style/global';
@import '../../../resources/style/colors';

@mixin icon-eye($crossed: false) {
    position: absolute;
    right: 10px;
    top: 6px;
    width: 24px;

    @if $crossed {
        top: 5.5px;
        content: url('../../../resources/img/icons/icon-eye-crossed.svg');
    }

    @else {
        content: url('../../../resources/img/icons/icon-eye.svg');
    }
}

/* PASSWORD */
#icon-eye {
    @include icon-eye(false);
}

#icon-eye-crossed {
    @include icon-eye(true);
}

div.checkbox {
    display: flex;
    align-items: center;
    padding-bottom: 14px;
}

a#register {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: $tenup-blue;
    border: 2.5px solid;
    font: 1rem GothamBold, sans-serif;
    text-decoration: none;
}

#password-input{
    padding-bottom: 0px;
}