@use '../../../resources/style/global';
@import 'src/webapp/resources/style/colors';

#reset-password-form {

  .invalid {
    color: $error;
    border-color: $error;

    input {
      color: $error;
    }

    input::placeholder {
      color: $error;
    }
  }

  /* FORM EMAIL */
  #email-input {
    width: 100%;
    height: auto;
    justify-content: center;
  }

  #email-input.invalid {
    color: $error;
    border-color: $error;

    input {
      color: $error;
    }

    input::placeholder {
      color: $error;
    }
  }

  /* FORM SEPARATOR */
  #separator {
    width: 100%;
    min-height: 20px;
    display: flex;
    justify-content: center;
    margin: 0;
    font: 14px GothamBold, sans-serif;
    text-transform: uppercase;
  }

  /* FORM QUADRUPLET */
  #quadruplet-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    #civility {
      justify-content: center;
    }

    #civility select {
      margin-bottom: 6px;
      max-height: 40px;
      padding: 0 20px 0 0;
      border: none;
      background: none;
      color: $primary-dark;
      font: 12px GothamBook, sans-serif;
    }

    #names {
      display: flex;
      justify-content: space-evenly;
      gap: 12px;
    }

    #birthdate {
      width: 100%;
      height: auto;
      display: inline;
    }

    #birthdate.invalid {
      color: $error;
      border-color: $error;

      fieldset {
        border-color: $error;
      }

      input {
        color: $error;
      }

      input::placeholder {
        color: $error;
      }
    }
  }
}