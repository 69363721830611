@import "src/webapp/resources/style/colors";
@import "src/webapp/resources/style/global";

.fft-success-reset-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: GothamBook, sans-serif;


  #header > #subtitle {
    display: flex;
    gap: 1.5em;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 1em;

  }

  .logos {
    display: grid;
    flex-wrap: wrap;
    width: 100%;
    grid-template-columns: repeat(3, auto);
    align-items: baseline;
    gap: 2em;


    &>div[class^='column']{
      display: block;
      text-align: center;

      width:100%;

      .store {
        grid-row: 1;
        width: 100%;
        &>img {
          width:75%;
          cursor: pointer;

        }

        &>img.logo-google-en {
          width: 86% !important;
          height: 86% !important;
        }
      }

      & > img:first-of-type {
        width: 100px;
        height: 100px;
        padding-bottom: 2em;
        padding-top: 2em;

      }
    }

    .column1 {
      grid-column: 1;
    }
    .column2 {
      grid-column: 2;
    }
    .column3 {
      grid-column: 3;
    }
    button {
      text-transform: uppercase;
    }
  }


  @media (min-width: 790px) {
    margin: 12vh 0 0;
  }
  @media (max-width: 789px) {
    margin: 6vh 0 0;
  }
}
