@import "../../../../resources/style/colors";

#register-credentials-form {
  .invalid {
    color: $error;
    border-color: $error;

    input {
      color: $error;
    }

    input::placeholder {
      color: $error;
    }
  }
  #country-phone-div{
    display: flex;
    gap: 12px;
  }

  #phone-input {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    padding-bottom: 16px;

    #country {
      flex-grow: 0;
      cursor: pointer;
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: space-between;
      width:100%;

      >img {
        width: 35px;
        height: 20px;
        align-self: center;
        object-fit: cover;
        border-radius: 5px;
      }

      >div {
        flex-grow: 0;
      }

      #country-dropdown {
        top: 43px;
      }
    }

    #phone {
      flex-grow: 1;

      fieldset {
        margin: 0;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }

      #phone-input-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 2px;

        span {
          margin: 4px 0;
        }
      }
    }
  }

  #phone-invalid {
    padding-bottom: 12px;
  }
}