@import "../../../../resources/style/colors";
@import "../../../../resources/style/global";

.fft-card {
  .fft-card-button {
    max-width: 325px;
    height: 42px;
    margin: auto;
    border-radius: 5px;

    @media (min-width: 901px) {
      padding: 16px 0;
    }
    @media (max-width: 900px) {
      padding: 12px 0;
    }

    & :is(button, a) {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-light;
      border: solid 2px;
      border-radius: 5px;
      text-decoration: none;
      text-transform: lowercase;
      font: 1rem GothamBold, sans-serif;

      span::first-letter {
        text-transform: uppercase !important;
      }

      @each $name, $color in $colors {
        &##{$name} {
          color: $color;
          border-color: $color;
        }
      }

      &:disabled {
        color: $primary-light !important;
        background-color: $disable-bg !important;
        border: none !important;
      }
      &.filled {
        border: 0;
        color: $primary-light !important;

        &:disabled {
          background-color: $disable-bg !important;
        }

        @each $name, $color in $colors {
          &##{$name} {
            background: $color;
          }
        }
      }
    }
  }
}
