@import "../../../resources/style/colors";

.fft-alert {
    a{
        color: $error;
        font: 12px  bold;
        font-family: "GothamBook", sans-serif;
        text-decoration-line: underline;
    }
}
