@import "../../../../../resources/style/colors";

.fft-text-button {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    width: 100%;
    display: block;
    color: $primary-dark;
    background: none;
    border: 0;
    border-radius: 5px;
    font: 1rem GothamBold, sans-serif;
  }
}
